import { getFandom } from '../util/api';

// ================ Action types ================ //

export const FETCH_FANDOM_REQUEST = 'app/fandomData/FETCH_FANDOM_REQUEST';
export const FETCH_FANDOM_SUCCESS = 'app/fandomData/FETCH_FANDOM_SUCCESS';
export const FETCH_FANDOM_ERROR = 'app/fandomData/FETCH_FANDOM_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities.
  loading: false,
  error: null,
  fandoms: [],
  fandomSelectable: [],
};

//get one fandom -> get fandom from state, if not dispatch get new fandom;

export default function fandomDataReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FANDOM_REQUEST:
      return { ...state, loading: true };
    case FETCH_FANDOM_SUCCESS:
      const fandom = payload;
      const fandomSelectable = {
        value: fandom.key,
        label: fandom.name,
      };

      return {
        ...state,
        fandoms: [...state.fandoms, fandom],
        fandomsSelectable: [...state.fandomSelectable, fandomSelectable],
        loading: false,
      };
    case FETCH_FANDOM_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
}

// ================ Selectors ================ //
/**
 * Get all fandoms
 *
 * @param {Object} state the full Redux store
 *
 * @return {Array<Object>} fandoms
 */
export const getAllFandoms = state => {
  return state.fandomData.fandoms;
};

export const getFandomAsyncInfo = state => {
  return { loading: state.fandomData.loading, error: state.fandomData.error };
};

// ================ Actions ================ //

export const fetchFandom = id => dispatch => {
  dispatch({ type: FETCH_FANDOM_REQUEST });

  return getFandom(id)
    .then(({ data }) => {
      //add fandom to state collection
      dispatch({ type: FETCH_FANDOM_SUCCESS, payload: data });
      return data;
    })
    .catch(error => {
      console.error(error.message);
      dispatch({ type: FETCH_FANDOM_ERROR, payload: error });
    });
};

export const getFandomRequest = fandomId => (dispatch, getState) => {
  const foundFandom = getState().fandomData.fandoms.filter(fandom => fandom.key == fandomId);
  if (!foundFandom[0]) {
    return dispatch(fetchFandom(fandomId)).then(res => {
      return Promise.resolve(res);
    });
  } else {
    return Promise.resolve(foundFandom[0]);
  }
};
