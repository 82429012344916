import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import ProductslistRow from '../ProductsListRow/ProductsListRow';

import css from './ProductList.module.scss';

interface IProps {
  products: { [key: string]: any }[];
  caption: string;
  isPurchased: boolean;
  isBuyer: boolean;
  urls: any;
  transactionId: any;
}

const ProductList = ({ products, caption, isPurchased, isBuyer, urls, transactionId }: IProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const accordion = classNames(css.itemListContainer, { [css.hide]: !isOpen });
  const hasUrls = urls && Object.keys(urls).length > 0;
  console.log("from productlist", urls);
  console.log(hasUrls, urls[products[0]?.id])

  return (
    <div className={css.productsContainer}>
      <div className={css.dropdownHeader}>
        <h4>
          {caption} ({products.length})
        </h4>
        <div
          className={css.dropdownSwitch}
          onClick={() => setIsOpen(!isOpen)}
          style={{ transform: `rotateZ(${isOpen ? '-180deg' : '0deg'})` }}
        >
          <FaChevronDown />
        </div>
      </div>
      <div className={accordion}>
        {products.map((product, index) => {
          return (
            <Fragment key={product.id}>
              <ProductslistRow
                transactionId={transactionId}
                intl={null}
                key={product.id}
                price={product.totalPrice}
                productId={product.id}
                productPreviewImage={product.imageUrl}
                quantity={product.quantity}
                allAttributes={product.attributes}
                variantType={product.variantType}
                productTitle={product.title}
                isPurchased={isPurchased}
                isDigital={true}
                isLink={product.digitalGoodType && product.digitalGoodType === 'link'}
                digitalGoodUrl={hasUrls && urls[product?.id] ? urls[product?.id] : ''}
                isBuyer={isBuyer}
                productType={product.productType}
                campaignTitle={product.campaignTitle}
              />
              {index !== products.length - 1 && <div className={css.rowDivider} />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ProductList;
