import React, { Fragment, ReactNode } from 'react';
import css from './NamedLinkButton.module.scss';
import NamedLink from '../NamedLink';
import ExternalLink from '../../ExternalLink/ExternalLink';

interface IProps {
  name?: string;
  params?: {
    [key: string]: string | number;
  };
  to?: {
    [key: string]: string | number;
  };
  children?: ReactNode;
  light?: boolean;
  external?: boolean;
  href?: string;
}

const NamedLinkButton = ({ name, params, to, children, light, href, external }: IProps) => {
  const linkStyles = {
    textDecoration: 'none',
    width: '100%'
  };
  const lightStyles = light
    ? {
        background: 'var(--fave-color-purple-s50)',
        color: 'var(--fave-color-purple-s500)',
      }
    : null;

  return (
    <Fragment>
      {external ? (
        <ExternalLink href={href} style={linkStyles}>
          <div style={lightStyles} className={css.buttonStyles}>
            {children}
          </div>
        </ExternalLink>
      ) : (
        <NamedLink style={linkStyles} name={name} to={to} params={params}>
          <div style={lightStyles} className={css.buttonStyles}>
            {children}
          </div>
        </NamedLink>
      )}
    </Fragment>
  );
};

export default NamedLinkButton;
