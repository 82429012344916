import React from 'react';
import { Field } from 'react-final-form';
import css from './FieldRadioSelect.module.scss';

const FieldRadioSelect = ({ id, name, options, ...rest }) => {
  return (
    <div className={css.container}>
      {options.map((option, index) => (
        <div className={css.optionContainer} key={index}>
          <div className={css.radioButtonWrapper}>
            <Field
              {...rest}
              className={css.radioButton}
              component="input"
              type="radio"
              id={id}
              name={name}
              value={option.value}
            />
          </div>
          <label className={css.optionLabel} htmlFor={option.value}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FieldRadioSelect;
