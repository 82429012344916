import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_SHOP_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_SHOP_DETAILS_REQUEST';
export const SAVE_SHOP_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_SHOP_DETAILS_SUCCESS';
export const SAVE_SHOP_NAME_ERROR = 'app/ContactDetailsPage/SAVE_SHOP_NAME_ERROR';

export const SAVE_SHOP_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_SHOP_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveShopNameError: null,
  saveShopDetailsInProgress: false,
  shopDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_SHOP_DETAILS_REQUEST:
      return {
        ...state,
        saveShopDetailsInProgress: true,
        saveShopNameError: null,
        shopDetailsChanged: false,
      };
    case SAVE_SHOP_DETAILS_SUCCESS:
      return { ...state, saveShopDetailsInProgress: false, shopDetailsChanged: true };
    case SAVE_SHOP_NAME_ERROR:
      return { ...state, saveShopDetailsInProgress: false, saveShopNameError: payload };
    case SAVE_SHOP_DETAILS_CLEAR:
      return {
        ...state,
        saveShopDetailsInProgress: false,
        saveShopNameError: null,
        shopDetailsChanged: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveShopDetailsRequest = () => ({ type: SAVE_SHOP_DETAILS_REQUEST });
export const saveShopDetailsSuccess = () => ({ type: SAVE_SHOP_DETAILS_SUCCESS });
export const saveEmailError = error => ({
  type: SAVE_SHOP_NAME_ERROR,
  payload: error,
  error: true,
});

export const saveShopDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a shop name update request to the API and return the current user.
 */
const requestSaveShopName = params => (dispatch, getState, sdk) => {
  dispatch(saveShopDetailsRequest());

  const shopName = params.shopName;
  const shopDescription = params.shopDescription;
  const shopPolicies = params.shopPolicies;
  const address = params.address;
  const apartment = params.apartment;
  const postalCode = params.postalCode;
  const city = params.city;
  const country = params.country;
  const state = params.state;
  const shopTwitter = params.shopTwitter;
  const shopInstagram = params.shopInstagram;
  const shopFacebook = params.shopFacebook;

  return sdk.currentUser
    .updateProfile(
      {
        publicData: {
          shopName,
          shopDescription,
          shopPolicies,
          address,
          apartment,
          postalCode,
          city,
          country,
          state,
          shopFacebook,
          shopInstagram,
          shopTwitter,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveShopNameError(storableError(e)));
      // pass the same error so that the SAVE_SHOP_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });
};

/**
 * Update shop details and fetch current user
 */
export const saveShopDetails = params => (dispatch, getState, sdk) => {
  const {
    shopName,
    currentShopName,
    shopDescription,
    currentShopDescription,
    shopPolicies,
    currentShopPolicies,
    address,
    currentAddress,
    apartment,
    currentApartment,
    postalCode,
    currentPostalCode,
    city,
    currentCity,
    country,
    currentCountry,
    state,
    currentState,
    shopTwitter,
    currentShopTwitter,
    shopInstagram,
    currentShopInstagram,
    shopFacebook,
    currentShopFacebook,
  } = params;

  const shopNameChanged = shopName !== currentShopName;
  const shopDescriptionChanged = shopDescription !== currentShopDescription;
  const shopPoliciesChanged = shopPolicies !== currentShopPolicies;

  const addressChanged = address !== currentAddress;
  const apartmentChanged = apartment !== currentApartment;
  const postalCodeChanged = postalCode !== currentPostalCode;
  const cityChanged = city !== currentCity;
  const countryChanged = country !== currentCountry;
  const stateChanged = state !== currentState;
  const shopFacebookChanged = shopFacebook !== currentShopFacebook;
  const shopTwitterChanged = shopTwitter !== currentShopTwitter;
  const shopInstagramChanged = shopInstagram !== currentShopInstagram;

  if (
    !(
      shopNameChanged ||
      shopDescriptionChanged ||
      shopPoliciesChanged ||
      addressChanged ||
      apartmentChanged ||
      postalCodeChanged ||
      cityChanged ||
      countryChanged ||
      stateChanged ||
      shopTwitterChanged ||
      shopFacebookChanged ||
      shopInstagramChanged
    )
  ) {
    return;
  }

  return (
    dispatch(
      requestSaveShopName({
        shopName,
        shopDescription,
        shopPolicies,
        address,
        apartment,
        postalCode,
        city,
        country,
        state,
        shopTwitter,
        shopFacebook,
        shopInstagram,
      })
    )
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveShopDetailsSuccess());
      })
      // error action dispatched in requestSaveShopName
      .catch(e => null)
  );
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
