import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import config from '../../config';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationListings } from '../../util/api.stripe';
import { denormalisedEntities, updatedEntities } from '../../util/data';

const months = [
  { month: 'Jan', value: 0 },
  { month: 'Feb', value: 1 },
  { month: 'Mar', value: 2 },
  { month: 'Apr', value: 3 },
  { month: 'May', value: 4 },
  { month: 'Jun', value: 5 },
  { month: 'Jul', value: 6 },
  { month: 'Aug', value: 7 },
  { month: 'Sep', value: 8 },
  { month: 'Oct', value: 9 },
  { month: 'Nov', value: 10 },
  { month: 'Dec', value: 11 },
];

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //
export const FETCH_OWN_LISTINGS_REQUEST = 'app/SellerDashboardPage/FETCH_OWN_LISTINGS_REQUEST';
export const FETCH_OWN_LISTINGS_SUCCESS = 'app/SellerDashboardPage/FETCH_OWN_LISTINGS_SUCCESS';
export const FETCH_OWN_LISTINGS_ERROR = 'app/SellerDashboardPage/FETCH_OWN_LISTINGS_ERROR';
export const ADD_OWN_ENTITIES = 'app/SellerDashboardPage/ADD_OWN_ENTITIES';

export const FETCH_ORDERS_OR_SALES_REQUEST =
  'app/SellerDashboardPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS =
  'app/SellerDashboardPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/SellerDashboardPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_GRAPH_DATA = 'app/SellerDashboardPage/FETCH_GRAPH_DATA';
export const FETCH_GRAPH_DATA_LOADING = 'app/SellerDashboardPage/FETCH_GRAPH_DATA_LOADING';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;

  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const initialState = {
  fetchInProgress: false,
  fetchOwnListingsError: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  listingRefs: [],
  transactionRefs: [],
};

export default function sellerDasboardPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_OWN_LISTINGS_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchOwnListingsError: null,
      };
    case FETCH_OWN_LISTINGS_SUCCESS:
      return {
        ...state,
        listingRefs: resultIds(payload.data),
      };
    case FETCH_OWN_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchInProgress: false, fetchOwnListingsError: payload };
    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_GRAPH_DATA:
      return {
        ...state,
        graphData: payload,
        graphDataLoading: false,
      };
    case FETCH_GRAPH_DATA_LOADING:
      return {
        ...state,
        graphDataLoading: true,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.SellerDashboardPage;

  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

const fetchOwnListingsRequest = () => ({ type: FETCH_OWN_LISTINGS_REQUEST });
const fetchOwnListingsSuccess = response => ({
  type: FETCH_OWN_LISTINGS_SUCCESS,
  payload: response,
});
const fetchOwnListingsError = e => ({
  type: FETCH_OWN_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchGraphData = graphData => ({ type: FETCH_GRAPH_DATA, payload: graphData });
const fetchGraphDataLoading = () => ({ type: FETCH_GRAPH_DATA_LOADING });

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

const fetchOwnListings = (params, search, dispatch, sdk) => {
  const { page = 1 } = parse(search);

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  const listingsQueryParams = {
    page: page,
    perPage: INBOX_PAGE_SIZE,
    include: ['images', 'currentStock', 'publicData'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
    pub_isVariant: false,
  };

  dispatch(fetchOwnListingsRequest());
  return sdk.currentUser.show().then(res => {
    const paramsWithUser = { ...listingsQueryParams, authorId: res.data.data.id.uuid };
    integrationListings(paramsWithUser)
      .then(response => {
        dispatch(addOwnEntities(response));
        dispatch(fetchOwnListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(fetchOwnListingsError(e));
        throw e;
      });
  });
};

const fetchTransactions = (params, search, dispatch, sdk) => {
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues['sales'];

  const { page = 1 } = parse(search);

  dispatch(fetchOrdersOrSalesRequest());

  const transactionsQueryParams = {
    only: onlyFilter,
    lastTransitions: TRANSITIONS,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(transactionsQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  fetchOwnListings(params, search, dispatch, sdk);
  return fetchTransactions(params, search, dispatch, sdk);
};

const adjustGraphData = (graphData, graphPeriod) => {
  if (graphPeriod === 'monthly') {
    let monthlyRevenueDataset = [];
    months.forEach(month => {
      const monthTotal = graphData.reduce((prev, curr) => {
        if (curr.date.getMonth() === month.value) {
          return prev + curr.purchaseTotal;
        } else {
          return prev;
        }
      }, 0);
      monthlyRevenueDataset = [...monthlyRevenueDataset, monthTotal / 100];
    });

    return monthlyRevenueDataset;
  }
};

export const loadGraphData = options => async (dispatch, getState, sdk) => {
  dispatch(fetchGraphDataLoading());
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues['sales'];
  const apiQueryParams = {
    perPage: 6,
    only: onlyFilter,
    lastTransitions: TRANSITIONS,
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
  };

  const res = await sdk.transactions.query(apiQueryParams);

  let combinedData = res.data.data;
  let { totalPages } = res.data.meta;
  if (totalPages > 1) {
    for (let i = 2; i <= totalPages; i++) {
      const newData = await sdk.transactions.query({
        ...apiQueryParams,
        page: i,
      });
      combinedData = [...combinedData, ...newData.data.data];
    }
  }

  const partial = combinedData.map(item => {
    const amount = item.attributes.payinTotal?.amount;
    return {
      date: item.attributes.lastTransitionedAt,
      purchaseTotal: amount ? amount : 0,
    };
  });

  const totalSales = partial.length;
  const adjustedData = adjustGraphData(partial, options.graphPeriod);

  const graphDataset = adjustedData
    ? {
        xAxis: adjustedData,
        yAxis: months.map(month => month.month),
      }
    : {
        xAxis: partial.map(item => item.date),
        yAxis: partial.map(item => item.purchaseTotal / 100),
      };

  dispatch(
    fetchGraphData({
      graphDataset,
      totalSales,
    })
  );
};
