import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReturnsAndRefunds.module.css';
import ExternalLink from '../ExternalLink/ExternalLink';

const ReturnsPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: May, 24th 2022</p>
      <p>
        Is the buyer asking to return a product? Do you, as the seller, need to refund the seller
        since you cannot fulfil the order? We are working to provide a good solution for refund &
        returns and while that, the process will be as simple as this:
      </p>
      <ol>
        <li>
          Buyers will contact you or you can contact buyer via email or message on the Fave
          marketplace - you can see your messages by going to Manage Shop &gt; Orders or clicking
          here.
        </li>
        <li>
          After receiving a refund request from buyers all you need to do is send the order details
          to us by{' '}
          <ExternalLink href="mailto:marketplace@faveforfans.com">
            marketplace@faveforfans.com
          </ExternalLink>
          .
        </li>
        <li>
          When we receive your email we will take care of the refund process for you, so you don’t
          need to worry about it for now.
        </li>
        <li>
          As the refund process is finished, we will inform and share the details with you by email.
        </li>
      </ol>
    </div>
  );
};

ReturnsPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReturnsPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReturnsPolicy;
