import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './ModalMissingInformation.module.css';
import Button from '../Button/Button';

const ShippingDetailsReminder = props => {
  const { className } = props;

  return (
    <div className={className}>
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.missingShippingDetailsTitle" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalMissingInformation.missingShippingDetailsText" />
      </p>

      <div className={css.bottomWrapper}>
        <NamedLink className={css.home} name="ShopDetailsPage" title={'Add'}>
          <Button>Add location</Button>
        </NamedLink>
      </div>
    </div>
  );
};

export default ShippingDetailsReminder;
