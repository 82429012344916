import React, { Component, useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';

import css from './ListingCard.module.css';
import VerifiedBadge from '../VerifiedBadge/VerifiedBadge';
import { isExclusiveCheck } from '../../ducks/ListingCard.duck';
import { getFandom } from '../../util/api';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponentFn = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isExclusiveInfo,
    isExclusiveCheck,
  } = props;

  useEffect(() => {
    isExclusiveCheck(listing.id.uuid);
  }, []);

  const isReferral = listing.attributes?.publicData?.isReferral;
  const isExclusive = isExclusiveInfo[listing.id.uuid] ? true : false;
  const exclusiveLevel = isExclusiveInfo[listing.id.uuid]?.level;

  const classes = classNames(rootClassName || css.root, className);

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const authorShopName = author.attributes.profile.publicData?.shopName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const [listingFandom, setListingFandom] = useState('');
  const hasListingFandom = listingFandom !== '';
  const fandomId = listing?.attributes?.publicData?.fandom;
  const hasFandomId = !!fandomId && fandomId !== '' && fandomId.length === 11;

  useEffect(() => {
    if (hasFandomId) {
      getFandom(fandomId)
        .then(res => {
          setListingFandom(res.data.name);
        })
        .catch(err => console.log(err));
    }
  }, [listing]);

  return (
    <>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <div className={css.imageContainer}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
            {isExclusive && (
              <VerifiedBadge
                gold={exclusiveLevel === 2}
                className={classNames(css.verifiedBadge, {
                  [css.verifiedBadgeGold]: exclusiveLevel === 2,
                })}
                text="Verified Fan"
              />
            )}
          </div>
        </AspectRatioWrapper>
        <div className={css.info}>
          <div className={css.mainInfo}>
            {showAuthorInfo ? (
              <div className={css.authorInfo}>
                <span className={css.authorName}>
                  {authorShopName && authorShopName !== '' ? authorShopName : authorName}
                </span>
              </div>
            ) : null}
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {hasListingFandom && <div className={css.listingFandom}>{listingFandom}</div>}
          </div>
        </div>
        {!isReferral && (
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {price.amount == 0 ? 'Free' : formattedPrice}
            </div>
            {config.listing.showUnitTypeTranslations ? (
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            ) : null}
          </div>
        )}
      </NamedLink>
    </>
  );
};

ListingCardComponentFn.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponentFn.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

const mapStateToProps = state => {
  const { isExclusiveInfo } = state.ListingCardReducer;
  return { isExclusiveInfo };
};

const mapDispatchToProps = dispatch => ({
  isExclusiveCheck: listingId => dispatch(isExclusiveCheck(listingId)),
});

const ListingCardComponent = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ListingCardComponentFn);

export default ListingCardComponent;
