import React from 'react';
import PropTypes from 'prop-types';

import css from './FullfillmentStatus.module.css';
import classNames from 'classnames';

import {
  txIsCanceled,
  txIsEnquired,
  txIsPurchased,
  txIsDelivered,
  txIsDisputed,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsReceived,
  txIsCompleted,
  txIsReviewedByCustomer,
  txIsReviewedByProvider,
  txIsReviewed,
} from '../../util/transaction';

//translated fullfilment status
export const txFullfillmentState = (intl, tx, type) => {
  if (txIsEnquired(tx)) {
    return {
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.unfullfilled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.unFullfilled',
      }),
    };
  } else if (txIsPaymentPending(tx)) {
    return {
      stateClassName: css.unfullfilled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.unFullfilled',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      stateClassName: css.canceled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.canceled',
      }),
    };
  } else if (txIsCanceled(tx)) {
    return {
      stateClassName: css.canceled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.canceled',
      }),
    };
  } else if (txIsPurchased(tx)) {
    return {
      stateClassName: css.unfullfilled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.unFullfilled',
      }),
    };
  } else if (txIsDelivered(tx)) {
    return {
      stateClassName: css.fullfilled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.fullfilled',
      }),
    };
  } else if (txIsDisputed(tx)) {
    return {
      stateClassName: css.fullfilled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.fullfilled',
      }),
    };
  } else if (txIsReceived(tx) || txIsCompleted(tx)) {
    return {
      stateClassName: css.fullfilled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.fullfilled',
      }),
    };
  } else if (txIsReviewedByCustomer(tx)) {
    const translationKey = 'InboxPageSeller.fullfilled';
    return {
      stateClassName: css.fullfilled,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewedByProvider(tx)) {
    const translationKey = 'InboxPageSeller.fullfilled';
    return {
      stateClassName: css.fullfilled,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewed(tx)) {
    return {
      stateClassName: css.fullfilled,
      state: intl.formatMessage({
        id: 'InboxPageSeller.fullfilled',
      }),
    };
  } else {
    console.warn('This fullfillemnt transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

export const FullfillmentStatus = props => {
  const { tx, intl } = props;
  const stateData = txFullfillmentState(intl, tx);

  return (
    <div className={classNames(stateData.stateClassName, css.fullfillmentRow)}>
      <div className={css.dot}> </div>
      {stateData.state}
    </div>
  );
};

const { object } = PropTypes;

FullfillmentStatus.defaultProps = {
  tx: null,
  intl: null,
};

FullfillmentStatus.propTypes = {
  tx: object,
  intl: object,
};

export default FullfillmentStatus;
