import React from 'react';
import PropTypes from 'prop-types';
import { FaCheck, FaTimes } from 'react-icons/fa';

import css from './PaymentStatus.module.css';

import {
  txIsCanceled,
  txIsEnquired,
  txIsPurchased,
  txIsDelivered,
  txIsDisputed,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsReceived,
  txIsCompleted,
  txIsReviewedByCustomer,
  txIsReviewedByProvider,
  txIsReviewed,
} from '../../util/transaction';

//translated payment status
export const txPaymentState = (intl, tx) => {
  const paymentFailedNode = (
    <div>
      <FaTimes />{' '}
      {intl.formatMessage({
        id: 'InboxPageSeller.paymentFailure',
      })}
    </div>
  );
  const paymentSuccessNode = (
    <div>
      <FaCheck />{' '}
      {intl.formatMessage({
        id: 'InboxPageSeller.paymentSuccessful',
      })}
    </div>
  );

  if (txIsEnquired(tx)) {
    return {
      stateClassName: css.paymentHidden,
      state: intl.formatMessage({
        id: 'InboxPageSeller.stateEnquiry',
      }),
    };
  } else if (txIsPaymentPending(tx)) {
    return {
      stateClassName: css.paymentPending,
      state: intl.formatMessage({
        id: 'InboxPageSeller.paymentPending',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      stateClassName: css.paymentFailed,
      state: paymentFailedNode,
    };
  } else if (txIsCanceled(tx)) {
    return {
      stateClassName: css.paymentRefunded,
      state: intl.formatMessage({
        id: 'InboxPageSeller.paymentRefunded',
      }),
    };
  } else if (txIsPurchased(tx)) {
    return {
      stateClassName: css.paymentSuccess,
      state: paymentSuccessNode,
    };
  } else if (txIsDelivered(tx)) {
    return {
      stateClassName: css.paymentSuccess,
      state: paymentSuccessNode,
    };
  } else if (txIsDisputed(tx)) {
    return {
      stateClassName: css.paymentSuccess,
      state: paymentSuccessNode,
    };
  } else if (txIsReceived(tx) || txIsCompleted(tx)) {
    return {
      stateClassName: css.paymentSuccess,
      state: paymentSuccessNode,
    };
  } else if (txIsReviewedByCustomer(tx)) {
    return {
      stateClassName: css.paymentSuccess,
      state: paymentSuccessNode,
    };
  } else if (txIsReviewedByProvider(tx)) {
    return {
      stateClassName: css.paymentSuccess,
      state: paymentSuccessNode,
    };
  } else if (txIsReviewed(tx)) {
    return {
      stateClassName: css.paymentSuccess,
      state: paymentSuccessNode,
    };
  } else {
    console.warn('This payment status is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

export const PaymentStatus = props => {
  const { tx, intl } = props;
  const stateData = txPaymentState(intl, tx);

  return (
    <div className={css.paymentStatus}>
      <div className={stateData.stateClassName}>{stateData.state}</div>
    </div>
  );
};

const { object } = PropTypes;

PaymentStatus.defaultProps = {
  tx: null,
  intl: null,
};

PaymentStatus.propTypes = {
  tx: object,
  intl: object,
};

export default PaymentStatus;
