// ================ Action types ================ //

export const IS_EXCLUSIVE_LISTING = 'app/ListingCardReducer/IS_EXCLUSIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  isExclusiveInfo: {},
};

export default function ListingCardReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case IS_EXCLUSIVE_LISTING:
      return {
        ...state,
        isExclusiveInfo: { ...state.isExclusiveInfo, [payload.id]: payload },
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
export const checkIsExclusive = info => {
  return {
    type: IS_EXCLUSIVE_LISTING,
    payload: info,
  };
};

// ================ Thunks ================ //

export const isExclusiveCheck = listingId => (dispatch, getState, sdk) => {
  sdk.listings.query({ ids: [listingId] }).then(res => {
    const excInfo = res.data.data[0].attributes.publicData?.exclusiveInfo;
    if (excInfo) {
      dispatch(
        checkIsExclusive({
          ...excInfo,
          id: res.data.data[0].id.uuid,
        })
      );
    }
  });
};

// Helper to fetch correct image variants for different thunk calls
