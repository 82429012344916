import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PRODUCTS_TOTAL, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTotalProductsPrice = props => {
  const { lineItems, intl } = props;

  const productsTotalLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PRODUCTS_TOTAL && !item.reversal
  );

  return productsTotalLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.productsTotal" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, productsTotalLineItem.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemTotalProductsPrice.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalProductsPrice;
