import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_TIP, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTipMaybe = props => {
  const { lineItems, intl } = props;

  const tipLineItem = lineItems.find(item => item.code === LINE_ITEM_TIP && !item.reversal);

  return tipLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.tip" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, tipLineItem.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemTipMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTipMaybe;
