import config from '../../config';
import { initiatePrivileged, stockManagement, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { currentUserShowSuccess, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { verificationExclusivity } from '../../util/verifiedFanHelper';

// ================ Action types ================ //

export const CURRENT_USER_REQUEST = 'app/CheckoutPage/CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'app/CheckoutPage/CURRENT_USER_SUCCESS';
export const CURRENT_USER_ERROR = 'app/CheckoutPage/CURRENT_USER_ERROR';

export const LISTINGS_TO_BUY_REQUEST = 'app/CheckoutPage/LISTINGS_TO_BUY_REQUEST';
export const LISTINGS_TO_BUY_SUCCESS = 'app/CheckoutPage/LISTINGS_TO_BUY_SUCCESS';
export const LISTINGS_TO_BUY_ERROR = 'app/CheckoutPage/LISTINGS_TO_BUY_ERROR';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const RECALCULATE_SPECULATE_TRANSACTION_REQUEST =
  'app/ListingPage/RECALCULATE_SPECULATE_TRANSACTION_REQUEST';

export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';
export const COUNTRY_SHIPPING_DECLINED = 'app/ListingPage/COUNTRY_SHIPPING_DECLINED';
export const TAX_SHIPPING_DECLINED = 'app/ListingPage/TAX_SHIPPING_DECLINED';

export const PAYPAL_CUSTOMER_REQUEST = 'app/CheckoutPage/PAYPAL_CUSTOMER_REQUEST';
export const PAYPAL_CUSTOMER_SUCCESS = 'app/CheckoutPage/PAYPAL_CUSTOMER_SUCCESS';
export const PAYPAL_CUSTOMER_ERROR = 'app/CheckoutPage/PAYPAL_CUSTOMER_ERROR';

export const UPDATE_STOCK = 'app/CheckoutPage/UPDATE_STOCK';
export const UPDATE_STOCK_ERROR = 'app/CheckoutPage/UPDATE_STOCK_ERROR';

export const UPDATE_PURCHASED_PRODUCTS_REQUEST =
  'app/CheckoutPage/UPDATE_PURCHASED_PRODUCTS_REQUEST';
export const UPDATE_PURCHASED_PRODUCTS_SUCCESS =
  'app/CheckoutPage/UPDATE_PURCHASED_PRODUCTS_SUCCESS';
export const UPDATE_PURCHASED_PRODUCTS_ERROR = 'app/CheckoutPage/UPDATE_PURCHASED_PRODUCTS_ERROR';

export const WAIT_FOR_REDIRECT_REQUEST = 'app/CheckoutPage/WAIT_FOR_REDIRECT_REQUEST';
export const WAIT_FOR_REDIRECT_SUCCESS = 'app/CheckoutPage/WAIT_FOR_REDIRECT_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  cart: null,
  orderData: null,
  fetchingDataInProgress: false,
  fetchingDataError: false,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  products: null,
  updatePurchasedProductsInProgress: false,
  updatePurchasedProductsError: null,
  waitingForRedirect: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case RECALCULATE_SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculatedTransaction: null,
        shippingToCountryForbidden: false,
        taxError: false,
        recalculateTransactionInProgess: true,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
        shippingToCountryForbidden: false,
        recalculateTransactionInProgess: false,
        taxError: false,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        recalculateTransactionInProgess: false,
        speculateTransactionError: payload,
      };
    case COUNTRY_SHIPPING_DECLINED:
      return {
        ...state,
        recalculateTransactionInProgess: false,
        speculateTransactionInProgress: false,
        shippingToCountryForbidden: true,
      };
    case TAX_SHIPPING_DECLINED:
      return {
        ...state,
        recalculateTransactionInProgess: false,
        speculateTransactionInProgress: false,
        taxError: true,
      };
    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case UPDATE_STOCK:
      return { ...state, updateStockConfirmation: true };
    case UPDATE_STOCK_ERROR:
      console.error(payload);
      return { ...state, updateStockError: payload, updateStockConfirmation: false };
    case CURRENT_USER_REQUEST:
      return { ...state, fetchingDataInProgress: true, fetchingDataError: false };
    case CURRENT_USER_SUCCESS:
      return {
        ...state,
        fetchingDataInProgress: false,
        products: payload,
        listing: payload[0],
        orderData: { quantity: payload[0]?.quantity },
      };
    case CURRENT_USER_ERROR:
      return {
        ...state,
        fetchingDataInProgress: false,
        speculateTransactionInProgress: false,
        fetchingDataError: true,
      };
    case LISTINGS_TO_BUY_REQUEST:
      return {
        ...state,
        fetchingDataInProgress: true,
        transaction: null,
        fetchingDataError: false,
      };
    case LISTINGS_TO_BUY_SUCCESS:
      return {
        ...state,
        fetchingDataInProgress: false,
        products: payload,
        listing: payload[0],
        orderData: { quantity: payload[0]?.quantity },
      };
    case LISTINGS_TO_BUY_ERROR:
      return {
        ...state,
        fetchingDataInProgress: false,
        speculateTransactionInProgress: false,
        fetchingDataError: true,
      };
    case UPDATE_PURCHASED_PRODUCTS_REQUEST:
      return {
        ...state,
        updatePurchasedProductsInProgress: true,
        updatePurchasedProductsError: null,
      };
    case UPDATE_PURCHASED_PRODUCTS_SUCCESS:
      return {
        ...state,
        image: null,
        updatePurchasedProductsInProgress: false,
      };
    case UPDATE_PURCHASED_PRODUCTS_ERROR:
      return {
        ...state,
        image: null,
        updatePurchasedProductsInProgress: false,
        updatePurchasedProductsError: payload,
      };
    case WAIT_FOR_REDIRECT_REQUEST:
      return {
        ...state,
        waitingForRedirect: true,
      };
    case WAIT_FOR_REDIRECT_SUCCESS:
      return {
        ...state,
        waitingForRedirect: false,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });
export const recalculatespeculateTransactionRequest = () => ({
  type: RECALCULATE_SPECULATE_TRANSACTION_REQUEST,
});

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const countryError = () => ({
  type: COUNTRY_SHIPPING_DECLINED,
});

export const taxError = () => ({
  type: TAX_SHIPPING_DECLINED,
});

export const paypalCustomerRequest = () => ({ type: PAYPAL_CUSTOMER_REQUEST });
export const paypalCustomerSuccess = () => ({ type: PAYPAL_CUSTOMER_SUCCESS });
export const paypalCustomerError = e => ({
  type: PAYPAL_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const updateStock = () => ({
  type: UPDATE_STOCK,
});

export const updateStockError = err => ({
  type: UPDATE_STOCK_ERROR,
  payload: err,
});

export const fetchCurrentUserRequest = () => ({
  type: CURRENT_USER_REQUEST,
});
export const fetchCurrentUserSuccess = user => ({
  type: CURRENT_USER_SUCCESS,
  payload: user,
});
export const fetchCurrentUserError = err => ({
  type: CURRENT_USER_ERROR,
  payload: err,
});

export const fetchListingsToBuyRequest = () => ({
  type: LISTINGS_TO_BUY_REQUEST,
});
export const fetchListingsToBuySuccess = listings => ({
  type: LISTINGS_TO_BUY_SUCCESS,
  payload: listings,
});
export const fetchListingsToBuyError = err => ({
  type: LISTINGS_TO_BUY_ERROR,
  payload: err,
});

// SDK method: sdk.currentUser.updateProfile
export const updatePurchasedProductsRequest = params => ({
  type: UPDATE_PURCHASED_PRODUCTS_REQUEST,
  payload: { params },
});
export const updatePurchasedProductsSuccess = result => ({
  type: UPDATE_PURCHASED_PRODUCTS_SUCCESS,
  payload: result.data,
});
export const updatePurchasedProductsError = error => ({
  type: UPDATE_PURCHASED_PRODUCTS_ERROR,
  payload: error,
  error: true,
});

export const initiateWaitForRedirect = () => {
  return {
    type: WAIT_FOR_REDIRECT_REQUEST,
  };
};
export const finishWaitForRedirect = () => {
  return {
    type: WAIT_FOR_REDIRECT_SUCCESS,
  };
};

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  return getProducts(sdk, orderParams).then(prodresult => {
    verifyStock(prodresult, orderParams, dispatch, initiateOrderError);

    // If we already have a transaction ID, we should transition, not
    // initiate.
    const isTransition = !!transactionId;
    const transition = isTransition
      ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
      : TRANSITION_REQUEST_PAYMENT;
    const isPrivilegedTransition = isPrivileged(transition);

    const { quantity, ...otherOrderParams } = orderParams;
    const quantityMaybe = quantity
      ? {
          stockReservationQuantity: quantity,
        }
      : {};

    // Parameters only for client app's server
    const orderData = {
      sellerShippingData: orderParams.sellerShippingData,
    };

    // Parameters for Flex API
    const transitionParams = {
      ...quantityMaybe,
      ...otherOrderParams,
    };

    const bodyParams = isTransition
      ? {
          id: transactionId,
          transition,
          params: transitionParams,
        }
      : {
          processAlias: config.transactionProcessAlias,
          transition,
          params: transitionParams,
        };
    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };

    const handleSucces = response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));

      return { order: order };
    };

    const handleError = e => {
      dispatch(initiateOrderError(storableError(e)));
      const transactionIdMaybe = transactionId
        ? {
            transactionId: transactionId.uuid,
          }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        ...quantityMaybe,
        ...orderData,
      });
      throw e;
    };

    if (isTransition && isPrivilegedTransition) {
      // transition privileged
      return transitionPrivileged({
        isSpeculative: false,
        orderData,
        bodyParams: {
          ...bodyParams,
        },
        queryParams,
      })
        .then(handleSucces)
        .catch(handleError);
    } else if (isTransition) {
      // transition non-privileged
      return sdk.transactions
        .transition(bodyParams, queryParams)
        .then(handleSucces)
        .catch(handleError);
    } else if (isPrivilegedTransition) {
      // initiate privileged
      return initiatePrivileged({
        isSpeculative: false,
        orderData,
        bodyParams,
        queryParams,
      })
        .then(handleSucces)
        .catch(handleError);
    } else {
      // initiate non-privileged
      return sdk.transactions
        .initiate(bodyParams, queryParams)
        .then(handleSucces)
        .catch(handleError);
    }
  });
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {
      protectedData: {
        paypalTansaction: orderParams.paypalTransaction,
      },
    },
  };

  return sdk.transactions
    .transition(bodyParams, {
      expand: true,
      include: ['provider'],
    })
    .then(response => {
      const order = response.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.order.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

const verifyStock = (prodresult, orderParams, dispatch, errorFunction) => {
  const itemsWithStock = prodresult.data.data.map(listing => ({
    ...listing,
    stock: prodresult.data.included.find(
      stock => stock.id.uuid === listing.relationships.currentStock.data.id.uuid
    ).attributes.quantity,
  }));
  const stockVerification = orderParams.products.map(item => {
    const stock = itemsWithStock.find(itemWithStock => itemWithStock.id.uuid === item.id.uuid)
      .stock;
    const name = itemsWithStock.find(itemWithStock => itemWithStock.id.uuid === item.id.uuid)
      .attributes.title;
    return { isStockOk: stock >= item.quantity, itemName: name };
  });

  if (stockVerification.map(item => item.isStockOk).includes(false)) {
    const message = 'There is not enough stock please try to adjust for the following item/s: ';
    const itemsWithNotEnoughStock = stockVerification
      .filter(item => !item.isStockOk)
      ?.map(item => item.itemName)
      .join(', ');
    return dispatch(
      errorFunction(
        storableError({
          message: `${message}${itemsWithNotEnoughStock}`,
          name: 'Stock Error',
          statusText: 'There is not enough stock, please try adjusting the purchase',
        })
      )
    );
  }
};

const verifyVariants = (prods, dispatch, errorFunction) => {
  prods?.data?.data.forEach(product => {
    const isVariant = product.attributes?.publicData?.isVariant;
    const hasVariants = product.attributes.publicData.attributes?.length > 0;

    const isAllowed = isVariant || (!isVariant && !hasVariants);
    if (!isAllowed) {
      dispatch(
        errorFunction(
          storableError({
            name: 'Variant Error',
            statusText:
              'One of selected products has no selected variant, go back to product page and try again',
          })
        )
      );
    }
  });
};

const verifyIsReferral = (prods, dispatch, errorFunction) => {
  prods?.data?.data.forEach(product => {
    const isReferral = product.attributes?.publicData?.isReferral;

    const isAllowed = !isReferral;
    if (!isAllowed) {
      dispatch(
        errorFunction(
          storableError({
            name: 'Refferal Error',
            statusText: 'One of selected products is referral link cannot purchase.',
          })
        )
      );
    }
  });
};

const getProducts = async (sdk, orderParams) => {
  const prods = await sdk.listings.query({
    ids: orderParams.products.map(product => product.id),
    include: ['currentStock'],
  });

  return prods;
};

const verifyExclusive = async (prodresult, dispatch, errorFunction, getState) => {
  let block = false;
  var itemsProcessed = 0;

  await prodresult?.data?.data.forEach(async (product, index, array) => {
    itemsProcessed++;

    const exclusiveInfo = product.attributes?.publicData?.exclusiveInfo?.isExclusive;
    const isFanVerified = await verificationExclusivity(
      product.attributes?.publicData?.exclusiveInfo,
      getState().user.currentUser?.attributes?.profile?.privateData.fanId
    );

    const isAllowed = !exclusiveInfo ? true : isFanVerified;
    if (!isAllowed) {
      block = true;
    }

    if (itemsProcessed === array.length) {
      if (block) {
        dispatch(
          errorFunction(
            storableError({
              name: 'Verified item',
              statusText:
                'Item is exclusive for verified fans, please login to your verified account or adjust purchase',
            })
          )
        );
      }
    }
  });
};

const verifyQuantityCap = async (prods, orderParams, dispatch, errorFunction, getState) => {
  const currentUser = getState().user.currentUser;

  const purchasedProducts = currentUser?.attributes?.profile?.privateData?.purchasedProducts || [];

  let block = false;

  prods?.data?.data.forEach(listing => {
    const hasPurchaseQuantityCap =
      !!listing.attributes?.publicData?.purchaseQuantityCap &&
      parseInt(listing.attributes?.publicData?.purchaseQuantityCap) > 0;

    const isProductAlreadyPurchased = purchasedProducts.some(
      product => product.id === listing.id.uuid
    );

    const purchasedQuantity = isProductAlreadyPurchased
      ? parseInt(purchasedProducts.find(product => product.id === listing.id.uuid)?.quantity)
      : 0;

    const purchaseQuantityCap = hasPurchaseQuantityCap
      ? parseInt(listing.attributes?.publicData.purchaseQuantityCap)
      : 0;

    const quantityToBuyNow = orderParams.products.find(x => x.id.uuid == listing.id.uuid).quantity;
    const isAllowed =
      !hasPurchaseQuantityCap ||
      parseInt(quantityToBuyNow) + purchasedQuantity <= purchaseQuantityCap;
    if (!isAllowed) {
      block = true;
    }
  });

  if (block) {
    return dispatch(
      errorFunction(
        storableError({
          name: 'QuantityCap Error',
          statusText:
            'There is quantity cap exeeded on one of the products, please try adjusting the purchase',
        })
      )
    );
  } else {
    return block;
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId, isRecalculate) => (
  dispatch,
  getState,
  sdk
) => {
  isRecalculate
    ? dispatch(recalculatespeculateTransactionRequest())
    : dispatch(speculateTransactionRequest());

  getProducts(sdk, orderParams).then(prodresult => {
    verifyIsReferral(prodresult, dispatch, speculateTransactionError);
    verifyStock(prodresult, orderParams, dispatch, speculateTransactionError);
    verifyQuantityCap(prodresult, orderParams, dispatch, speculateTransactionError, getState);
    verifyVariants(prodresult, dispatch, speculateTransactionError);
    verifyExclusive(prodresult, dispatch, speculateTransactionError, getState);

    // If we already have a transaction ID, we should transition, not
    // initiate.
    const isTransition = !!transactionId;
    const transition = isTransition
      ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
      : TRANSITION_REQUEST_PAYMENT;
    const isPrivilegedTransition = isPrivileged(transition);

    const { quantity, sellerShippingData, ...otherOrderParams } = orderParams;
    const quantityMaybe = quantity
      ? {
          stockReservationQuantity: quantity,
        }
      : {};

    // Parameters only for client app's server
    const orderData = {
      sellerShippingData: sellerShippingData,
    };

    // Parameters for Flex API
    const transitionParams = {
      ...quantityMaybe,
      ...otherOrderParams,
      cardToken: 'CheckoutPage_speculative_card_token',
    };

    const bodyParams = isTransition
      ? {
          id: transactionId,
          transition,
          params: transitionParams,
        }
      : {
          processAlias: config.transactionProcessAlias,
          transition,
          params: transitionParams,
        };

    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };

    const handleSuccess = response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the speculate response');
      }
      const tx = entities[0];
      dispatch(speculateTransactionSuccess(tx));
    };

    const handleError = e => {
      log.error(e, 'speculate-transaction-failed', {
        listingId: transitionParams.listingId?.uuid,
        ...quantityMaybe,
        ...orderData,
      });

      if (e.status == 603) {
        return dispatch(countryError());
      } else if (e.status == 602) {
        return dispatch(taxError());
      } else {
        return dispatch(speculateTransactionError(storableError(e)));
      }
    };

    if (isTransition && isPrivilegedTransition) {
      // transition privileged
      return transitionPrivileged({
        isSpeculative: true,
        orderData,
        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    } else if (isTransition) {
      // transition non-privileged
      return sdk.transactions
        .transitionSpeculative(bodyParams, queryParams)
        .then(handleSuccess)
        .catch(handleError);
    } else if (isPrivilegedTransition) {
      // initiate privileged
      return initiatePrivileged({
        isSpeculative: true,
        orderData,
        bodyParams,
        queryParams,
      })
        .then(handleSuccess)
        .catch(handleError);
    } else {
      // initiate non-privileged
      return sdk.transactions
        .initiateSpeculative(bodyParams, queryParams)
        .then(handleSuccess)
        .catch(handleError);
    }
  });
};

export const getOrderData = searchParams => (dispatch, getState, sdk) => {
  const quantity = searchParams.get('quantity');
  const listingId = searchParams.get('id');
  const cartSellerId = searchParams.get('sellerId');
  const getQueryParams = ids => ({
    ids: ids,
    include: ['images', 'author', 'currentStock'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  });
  const substitutePics = (listings, parentListings) => {
    listings.data.data.forEach(element => {
      const parent = parentListings.data.data.find(
        parent => (parent.id.uuid = element.attributes.publicData.variantOf)
      );
      if (parent) {
        element.relationships.images = parent.relationships.images;
      }
    });
  };
  const getParentIds = listings => {
    const parentIdsToGetPictures = [];
    listings.forEach(listing => {
      if (listing.attributes.publicData.isVariant) {
        parentIdsToGetPictures.push(listing.attributes.publicData.variantOf);
      }
    });
    return parentIdsToGetPictures;
  };

  dispatch(fetchCurrentUserRequest());
  return sdk.currentUser
    .show()
    .then(result => {
      const user = result.data.data;
      if (cartSellerId) {
        const cart = user.attributes.profile.privateData.shoppingCart;
        const storePurchase = cart.find(x => x.sellerId == cartSellerId);
        const queryListings = storePurchase?.products.map(x => x.id);
        if (!queryListings) {
          return dispatch(fetchListingsToBuyError());
        }
        let listings;
        let parentListings;
        return sdk.listings
          .query(getQueryParams(queryListings))
          .then(data2 => {
            listings = data2;
            const parentIdsToGetPictures = getParentIds(listings.data.data);

            return sdk.listings.query(getQueryParams(parentIdsToGetPictures)).then(data => {
              parentListings = data;
              substitutePics(listings, parentListings);

              const listingsMapped = listings.data.data.map(listing => {
                return {
                  ...listing,
                  quantity: storePurchase.products.find(y => y.id == listing.id.uuid).quantity,
                };
              });

              dispatch(addMarketplaceEntities(listings));
              dispatch(addMarketplaceEntities(parentListings));
              dispatch(fetchListingsToBuySuccess(listingsMapped));

              const product = listingsMapped[0];
              dispatch(
                speculateTransaction({
                  buyerAddress: {},
                  products: listingsMapped,
                  listingId: product.id,
                  quantity: parseFloat(listingsMapped[0].quantity),
                })
              );
            });
          })
          .catch(err => {
            dispatch(fetchListingsToBuyError(err));
          });
      } else if (listingId && quantity) {
        dispatch(fetchListingsToBuyRequest());
        let listings;
        let parentListings;

        return sdk.listings
          .query(getQueryParams([listingId]))
          .then(data2 => {
            listings = data2;
            const parentIdsToGetPictures = getParentIds(listings.data.data);
            [];
            listings.data.data.forEach(listing => {
              if (listing.attributes.publicData.isVariant) {
                parentIdsToGetPictures.push(listing.attributes.publicData.variantOf);
              }
            });

            return sdk.listings.query(getQueryParams(parentIdsToGetPictures)).then(data => {
              parentListings = data;
              substitutePics(listings, parentListings);

              dispatch(addMarketplaceEntities(listings));
              dispatch(addMarketplaceEntities(parentListings));
              const product = listings.data.data[0];
              const author = listings.data.included.find(x => x.type == 'user');
              product.quantity = quantity;
              dispatch(fetchListingsToBuySuccess([product]));
              dispatch(
                speculateTransaction({
                  buyerAddress: {},
                  products: [product],
                  listingId: product.id.uuid,
                  quantity: parseFloat(quantity),
                  sellerShippingData: { ...author.attributes.profile.publicData },
                })
              );
            });
          })
          .catch(err => {
            console.warn(err);
            dispatch(fetchListingsToBuyError(err));
          });
      } else {
        dispatch(fetchListingsToBuyError());
      }
    })
    .catch(err => {
      console.error(err);
      dispatch(fetchCurrentUserError(err));
      dispatch;
    });
};

export const updatePurchasedProducts = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updatePurchasedProductsRequest());
    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updatePurchasedProductsSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updatePurchasedProducts(storableError(e))));
  };
};

export const updateListingsStock = body => (dispatch, getState, sdk) => {
  try {
    stockManagement(body).then(res => dispatch(updateStock(res)));
  } catch (err) {
    dispatch(updateStockError(err));
  }
};

export const loadData = () => (dispatch, getState) => {
  const urlParams = new URLSearchParams(location?.search);
  return dispatch(getOrderData(urlParams));
};
