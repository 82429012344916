import { getLevelForFandom } from './api';

export const verificationExclusivity = async (exclusiveListingInfo, userFaveId) => {
  if (exclusiveListingInfo?.isExclusive) {
    const fandomToCheck = exclusiveListingInfo ? exclusiveListingInfo?.fandoms[0] : 'unknown';
    return getLevelForFandom(userFaveId, fandomToCheck).then(resp => {
      return resp.data.level >= exclusiveListingInfo.level;
    });
  } else return true;
};
