import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const GET_CMS_DATA_REQUEST = 'app/LandingPage/GET_CMS_DATA_REQUEST';
export const GET_CMS_DATA_SUCCESS = 'app/LandingPage/GET_CMS_DATA_SUCCESS';
export const GET_CMS_DATA_ERROR = 'app/LandingPage/GET_CMS_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  loading: false,
  loadingInitial: true,
};

const digitalGoodsShowcaseReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CMS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        searchListingsError: null,
      };
    case GET_CMS_DATA_SUCCESS:
      return {
        ...state,
        cmsData: payload,
        loading: false,
        loadingInitial: false,
      };
    case GET_CMS_DATA_ERROR:
      console.error(payload);
      return {
        ...state,
        loading: false,
        cmsError: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default digitalGoodsShowcaseReducer;

// ================ Action creators ================ //

const getCmsDataRequest = () => {
  return { type: GET_CMS_DATA_REQUEST };
};
const getCmsDataSuccess = response => {
  return {
    type: GET_CMS_DATA_SUCCESS,
    payload: response,
  };
};
const getCmsDataError = error => {
  return {
    type: GET_CMS_DATA_ERROR,
    payload: error,
  };
};

// ================ Thunks ================ //

export const getCmsData = params => async (dispatch, getState, sdk) => {
  dispatch(getCmsDataRequest());
  if (process.env.REACT_APP_SANITY_PROJECT) {
    const client = sanityClient({
      projectId: process.env.REACT_APP_SANITY_PROJECT,
      dataset: process.env.REACT_APP_SANITY_DATASET,
      apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
      token: '', // or leave blank for unauthenticated usage
      useCdn: process.env.REACT_APP_SANITY_DATASET == 'staging' ? false : true, // `false` if you want to ensure fresh data
    });

    const query = `*[_type in ['mktCampaign'] && title == '${params.campaignId}']`;

    return client.fetch(query).then(response => {
      const builder = imageUrlBuilder(client);
      if (response[0]) {
        if (response[0].embeddedPage.header) {
          response[0].embeddedPage.header.logo = response[0].embeddedPage.header?.logo
            ? builder.image(response[0].embeddedPage.header.logo).url()
            : '';
        }
        if (response[0].embeddedPage.itemDescription) {
          response[0].embeddedPage.itemDescription.image = response[0].embeddedPage.itemDescription
            .image
            ? builder.image(response[0].embeddedPage.itemDescription.image).url()
            : '';
          response[0].embeddedPage.itemDescription.logo = response[0].embeddedPage.itemDescription
            ?.logo
            ? builder.image(response[0].embeddedPage.itemDescription.logo).url()
            : '';
        }
        if (response[0].embeddedPage.style) {
          response[0].embeddedPage.style.image = response[0].embeddedPage.style?.image
            ? builder.image(response[0].embeddedPage.style.image).url()
            : '';
        }
        dispatch(getCmsDataSuccess(response[0]));
      } else {
        dispatch(getCmsDataError('no no results for digitalgood given'));
      }
    });
  } else {
    return Promise.resolve(dispatch(getCmsDataError('no results for digitalgood')));
  }
};

export const getPreviewCmsData = draftId => async (dispatch, getState, sdk) => {
  dispatch(getCmsDataRequest());
  if (process.env.REACT_APP_SANITY_PROJECT) {
    const client = sanityClient({
      projectId: process.env.REACT_APP_SANITY_PROJECT,
      dataset: 'production',
      apiVersion: '2021-03-25',
      withCredentials: true,
      useCdn: false,
    });

    const query = `*[_type in ['mktCampaign'] && _rev == '${draftId}']`;

    return client.fetch(query).then(response => {
      const builder = imageUrlBuilder(client);
      if (response[0]) {
        response[0].embeddedPage.header.logo = response[0].embeddedPage.header.logo
          ? builder.image(response[0].embeddedPage.header.logo).url()
          : '';
        response[0].embeddedPage.itemDescription.image = response[0].embeddedPage.itemDescription
          .image
          ? builder.image(response[0].embeddedPage.itemDescription.image).url()
          : '';
        response[0].embeddedPage.itemDescription.logo = response[0].embeddedPage.itemDescription
          .logo
          ? builder.image(response[0].embeddedPage.itemDescription.logo).url()
          : '';
        dispatch(getCmsDataSuccess(response[0]));
      } else {
        dispatch(getCmsDataError('no results for digitalgood'));
      }
    });
  } else {
    dispatch(getCmsDataError('no results for digitalgood'));
  }
};

export const loadData = params => dispatch => {
  if (params.pageType == 'preview') {
    return dispatch(getPreviewCmsData(params.id));
  } else {
    return dispatch(getCmsData(params));
  }
};
