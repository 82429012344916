import React from 'react';

export default function PadlockIcon({ className }) {
  return (
    <svg className={className} width="13" height="14" viewBox="0 0 13 14" fill="none">
      <path
        d="M11.0137 6.125L10.3574 6.125L10.3574 4.15625C10.3574 1.86484 8.49258 1.72884e-07 6.20117 1.25815e-07C3.90977 7.87469e-08 2.04492 1.86484 2.04492 4.15625L2.04492 6.125L1.38867 6.125C0.66406 6.125 0.0761694 6.71289 0.0761691 7.4375L0.0761672 12.6875C0.0761669 13.4121 0.664057 14 1.38867 14L11.0137 14C11.7383 14 12.3262 13.4121 12.3262 12.6875L12.3262 7.4375C12.3262 6.71289 11.7383 6.125 11.0137 6.125ZM8.16992 6.125L4.23242 6.125L4.23242 4.15625C4.23242 3.0707 5.11562 2.1875 6.20117 2.1875C7.28672 2.1875 8.16992 3.0707 8.16992 4.15625L8.16992 6.125Z"
        fill="#B7B7B7"
      />
    </svg>
  );
}
