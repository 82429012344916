import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import BagIcon from '../Topbar/BagIcon';
import css from './ShoppingCartTopLink.module.css';
import { getCartSummary } from '../../containers/ShoppingCart/ShoppingCart.duck';
import { connect } from 'react-redux';
import { priceData } from '../ListingCard/ListingCard';
import { injectIntl } from 'react-intl';
import { Money } from '../../util/currency';

const money = new Money(1);

function ShoppingCartTopLink({ getSummary, cartSummary, currentUser, intl, cart }) {
  useEffect(() => {
    getSummary(currentUser);
  }, [currentUser]);

  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (cartSummary?.totalValue) {
      const { formattedPrice, _priceTitle } = priceData(
        new Money(cartSummary.totalValue || 0, cartSummary.currency),
        intl
      );
      setPrice(formattedPrice);
    } else {
      const { formattedPrice, _priceTitle } = priceData(new Money(0, 'USD'), intl);
      setPrice(formattedPrice);
    }
  }, [cartSummary, cart]);

  return (
    <div className={css.ShoppingCart}>
      <h4 className={css.Price}>{price}</h4>
      <BagIcon className={css.BagIcon} />
      {cartSummary?.itemsCount > 0 && (
        <div className={css.countCircle}>{cartSummary?.itemsCount}</div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { cartSummary } = state.ShoppingCart;
  const { ShoppingCart: cart } = state;
  return { cartSummary, cart, currentUser };
};

const mapDispatchToProps = dispatch => {
  return {
    getSummary: user => dispatch(getCartSummary(user)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ShoppingCartTopLink);
