import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './VerifiedBadge.module.scss';

export default function({ className, gold = false }) {
  return (
    <div className={classNames(css.root, { [css.goldPill]: gold }, className)}>
      <section
        className={classNames(css.svgContainerSection, { [css.svgContainerSectionGold]: gold })}
      >
        <svg className={css.star} height="20" width="20" viewBox="0 0 18 18" fill="none">
          <path
            d="M7.34358 1.76236C8.1754 0.665333 9.8246 0.665332 10.6564 1.76236C11.2195 2.50492 12.1393 2.88594 13.0625 2.759C14.4264 2.57147 15.5926 3.73763 15.4051 5.10154C15.2781 6.02474 15.6591 6.9446 16.4017 7.50764C17.4987 8.33946 17.4987 9.98866 16.4017 10.8205C15.6591 11.3835 15.2781 12.3034 15.4051 13.2266C15.5926 14.5905 14.4264 15.7567 13.0625 15.5691C12.1393 15.4422 11.2195 15.8232 10.6564 16.5658C9.8246 17.6628 8.1754 17.6628 7.34358 16.5658C6.78053 15.8232 5.86067 15.4422 4.93748 15.5691C3.57357 15.7567 2.40741 14.5905 2.59494 13.2266C2.72187 12.3034 2.34086 11.3835 1.5983 10.8205C0.50127 9.98866 0.50127 8.33946 1.5983 7.50764C2.34086 6.9446 2.72187 6.02474 2.59494 5.10154C2.40741 3.73763 3.57357 2.57147 4.93748 2.759C5.86067 2.88594 6.78053 2.50492 7.34358 1.76236Z"
            fill={gold ? '#8052FF' : '#FAF9FE'}
            stroke={gold ? 'white' : '#BA9EFF'}
            strokeWidth="0.5"
          />
        </svg>
        <svg className={css.tick} height="20" width="20" viewBox="0 0 18 18" fill="none">
          <path
            transform="translate(3.98437 , 5.27132)"
            d="M3.39644 7.7462L0.14644 4.49621C-0.0488135 4.30095 -0.0488135 3.98437 0.14644 3.7891L0.85353 3.08199C1.04878 2.88671 1.36539 2.88671 1.56064 3.08199L3.75 5.27132L8.43935 0.581987C8.63461 0.386733 8.95121 0.386733 9.14646 0.581987L9.85355 1.2891C10.0488 1.48435 10.0488 1.80093 9.85355 1.99621L4.10355 7.74622C3.90828 7.94148 3.59169 7.94148 3.39644 7.7462Z"
            fill={gold ? 'white' : '#8052FF'}
          />
        </svg>
      </section>
      <h5 className={classNames(css.text, { [css.textGold]: gold })}>
        <FormattedMessage
          id={gold ? 'verifiedBadge.verifiedSuperfan' : 'verifiedBadge.verifiedFan'}
        />
      </h5>
    </div>
  );
}
