import React from 'react';
import css from './DownloadAppModal.module.scss';
import Modal from '../Modal/Modal';
import { FormattedMessage } from 'react-intl';
import googleStore from '../../assets/googleStore.svg';
import appStore from '../../assets/appStore.svg';
import qrCode from '../../assets/qrCode.svg';

export default function DownloadAppModal({ onClose, isOpen, forItem = false }) {
  return (
    <Modal
      isOpen={isOpen}
      id="downloadAppModal"
      onManageDisableScrolling={() => {}}
      onClose={onClose}
      contentClassName={css.root}
      containerClass={css.modalContainer}
      scrolLayerClasses={css.scrolLayerClasses}
      className={isOpen ? css.isOpen : ''}
      isNotFullScreenMobile={true}
    >
      <h2 className={css.header}>
        {forItem ? (
          <FormattedMessage id="DownloadAppModal.itemHeader" />
        ) : (
          <FormattedMessage id="DownloadAppModal.defaultHeader" />
        )}
      </h2>
      <p className={css.text}>
        {forItem ? <FormattedMessage id="DownloadAppModal.itemBody" /> : null}
      </p>
      <section className={css.imagesContainer}>
        <div className={css.qrCodeContainer}>
          <img src={qrCode} />
        </div>
        <div className={css.storeContainer}>
          <a
            className={css.margin}
            href="https://play.google.com/store/apps/details?id=com.faveforfans.fave&gl=AT"
          >
            <img src={googleStore} />
          </a>
          <a href="https://apps.apple.com/om/app/fave-for-passionate-fans/id1558839284">
            <img src={appStore} />
          </a>
        </div>
      </section>
    </Modal>
  );
}
