import React from 'react';
import { Field } from 'react-final-form';

import css from './FieldFaveCheckbox.module.scss';

const FieldFaveCheckbox = ({ id, name, label, ...rest }) => {
  return (
    <div className={css.container}>
      <Field
        {...rest}
        className={css.checkbox}
        component="input"
        type="checkbox"
        id={id}
        name={name}
      ></Field>
      <label className={css.label} htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default FieldFaveCheckbox;
