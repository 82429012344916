import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import css from './FormattedAttributes.module.css';

function FormattedAttributes({ variantType, allAttributes }) {
  {
    return variantType?.attr ? (
      <div className={css.variants}>
        {variantType?.attr &&
          variantType.attr.map(variant => {
            const typeArr = variant.split('_');
            const attribute = allAttributes.find(a => {
              const attrTy = typeArr[0].split('-');
              const type = {
                type: attrTy[0],
                id: attrTy[1],
              };

              return type.id ? a.id == type.id && a.type == type.type : a.type == type.type;
            });
            const attributeValue = attribute?.values.find(v => v.value == typeArr[1]);
            return (
              <Fragment key={variant}>
                <span className={css.variantTitle}>{attribute.title}:&#0020;</span>
                <span className={css.variantInfo}>{attributeValue.label}</span>
              </Fragment>
            );
          })}
      </div>
    ) : null;
  }
}

FormattedAttributes.propTypes = {
  product: PropTypes.object,
};

export default FormattedAttributes;
