import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ReturnsPolicy.module.css';
import ExternalLink from '../ExternalLink/ExternalLink';

const ReturnsPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: May, 24th 2022</p>
      <p>
        If you need to return or exchange an item, the seller you purchased your order from is the
        best person to help you. Each seller on Fave manages their own orders, and makes decisions
        about cancellations, refunds, and returns.
      </p>
      <h2>How do refunds and returns work on Fave?</h2>
      <p>
        Sellers aren’t required to accept returns, exchanges, or provide a refund unless stated in
        their shop policies. You can find the seller's policy on their User Profile on the Fave app,
        and navigating to Shop Details. If the seller is willing to accept this return, be ready to
        discuss the following details before returning the item: Where you should send the return
        What time frame does the seller wish to receive the return Who will be responsible for the
        cost of return shipping, if not written in the shop's policies What shipping method to use
      </p>
      <h2>What are shop policies?</h2>
      <p>
        Each seller on Fave is responsible for their own policies regarding refunds, exchanges, and
        returns. Policies vary from shop to shop. Before making a purchase, you should review the
        shop’s Returns & exchanges policy. You can find the seller's policy on their User Profile on
        the Fave app, and navigating to Shop Details.
      </p>
      <h2>I still need help</h2>
      <p>
        If you messaged the seller, and opened a case, and you didn’t receive the help you need,
        please contact{' '}
        <ExternalLink href="mailto:marketplace@faveforfans.com<">
          marketplace@faveforfans.com
        </ExternalLink>{' '}
        and we'll do our best to support you.
      </p>
    </div>
  );
};

ReturnsPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReturnsPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReturnsPolicy;
